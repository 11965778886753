<template>
  <div class="page-content-box">
    <div
      class="info-banner"
      :style="{
        backgroundImage: 'url(' + bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <HeadMain class="head-white no-fixed"></HeadMain>
      <div class="custon-bread">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">非遗搜索</el-breadcrumb-item>
          <el-breadcrumb-item>搜索结果</el-breadcrumb-item>
        </el-breadcrumb> -->
      </div>
      <div class="city-introduce">
        <div class="search-box" style="top:10px">
          <el-input v-model="state" placeholder="请输入内容" @keyup.native.enter="handleCurrentChange(1)">
            <span class="search-span" slot="suffix" @click="handleCurrentChange(1)">
              <i class="el-icon-search el-input__icon"> </i>搜索
            </span>
          </el-input>
        </div>
      </div>
    </div>

    <div v-loading="false" class="search-list-box">
      <div class="seach-all-box">
        <div class="search-info">
          平台为您找到相关“<span class="red-c">{{ stateResult }}</span
          >”数据为 <span class="red-c">{{ total }}</span
          >条
        </div>
        <div class="sx-select">
          <label>筛选结果</label>
          <el-select v-model="type" placeholder="请选择" @change="handleCurrentChange(1)">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
      </div>
      <ul class="search-list">
        <li v-for="(item, index) in list" :key="item.id">
          <p class="search-p" @click="toContent(item)">{{ item.contentName }}</p>
          <span class="r-system">{{ item.columnName }}</span>
        </li>
      </ul>
      <div class="custon-page" v-if="pageCount > 1">
        <el-button class="page-btn" @click="handleCurrentChange(1)"><span>首页</span></el-button>
        <el-pagination
          :current-page.sync="currentPage"
          layout="prev, pager, next,slot,jumper"
          :pager-count="7"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          :page-count="pageCount"
          @current-change="search"
        >
          <el-button class="page-btn" @click="handleCurrentChange(pageCount)"><span>尾页</span></el-button>
          <span class="totals">第{{ currentPage }}页/共{{ total }}页</span>
        </el-pagination>
      </div>
    </div>
    <BottoCom class="bottom-white no-fixed"></BottoCom>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'

export default {
  components: {
    HeadMain,
    BottoCom
  },
  data() {
    return {
      bannerImg: require('../../assets/images/s-img.png'),
      state: this.$route.params.searchText,
      stateResult: '',
      loading: false,
      options: [
        {
          value: null,
          label: '全部'
        },
        {
          value: 3,
          label: '非遗项目'
        },
        {
          value: 4,
          label: '传承人'
        },
        {
          value: 0,
          label: '资讯动态'
        },
        {
          value: 1,
          label: '非遗政策'
        }
      ],
      type: null,
      list: [],
      currentPage: 1,
      total: 0,
      pageCount: 0
    }
  },
  methods: {
    search(ev) {
      if (this.state) {
        let params = {
          contentName: this.state,
          current: this.currentPage,
          contentType: [0, 1].includes(this.type) ? 1 : this.type,
          detailContentType: [0, 1].includes(this.type) ? this.type : null
        }
        this.axios
          .get('/api/portal/cont/pub-search', { params })
          .then(({ data }) => {
            this.stateResult = this.state
            this.list = data.records
            this.total = data.total
            this.pageCount = data.pages
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$message.warning('请输入内容')
      }
    },
    handleCurrentChange(current) {
      this.currentPage = current
      this.search()
    },
    toContent({ contentUrl, id, contentId }) {
      this.$router.push({ path: contentUrl, query: { id, contentId } })
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'page-body city-body equipment-body'
    this.search()
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body city-body')
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/index';
.city-contnt-one .city-zx-box .city-zx-r .dynamic-list li {
  margin-bottom: 0px;
}
.dynamic-list {
  padding: 0;
}
.dynamic-box .dynamic-con {
  margin-left: 20px;
}
.el-select-dropdown__list {
  border: 1px solid #d4a76b;
}

.el-select-dropdown__item {
  text-align: center;
  font-size: 16px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222222;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: transparent;
  color: #b93333;
}
</style>
